.ant-tabs {
    height: 100%;
}
.ant-tabs-tab{
    margin-right: 3px !important;
}
.custom-tabs {
    .ant-tabs-content-holder {
        height: 100%;
    }
}
.ant-tabs-content {
    height: 100%;
}

