.floor-plan-editor-container .minimap {
    right: 16px !important;
    top: 16px !important;
    border-radius: 4px;
    border: 1px solid var(--neutral-8, #595959);
    /* drop-shadow/0.12+0.8+0.5 */
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}


g[id*="mainG"] {
    stroke: var(--neutral-8, #595959);
    border-radius: 4px;
}
