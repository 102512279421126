.marginFactory (@name, @size) {

    .mt-@{name}{
      margin-top: ~"@{size}rem";      
    }
  
    .mr-@{name} {
      margin-right: ~"@{size}rem";
    }
  
    .mb-@{name} {
      margin-bottom: ~"@{size}rem";
    }
  
    .ml-@{name} {
      margin-left: ~"@{size}rem";
    }

    .m-@{name} {
      margin: ~"@{size}rem"
    }

    .mx-@{name} {
      margin-left: ~"@{size}rem";
      margin-right: ~"@{size}rem"
    }

    .my-@{name} {
      margin-top: ~"@{size}rem";
      margin-bottom: ~"@{size}rem";
    }
  
}

.marginFactory(0, 0);

.marginFactory(3, 0.1878);

.marginFactory(4, 0.2504);

.marginFactory(5, 0.313);

.marginFactory(6, 0.3756);

.marginFactory(7, 0.4382);

.marginFactory(8, 0.500);

.marginFactory(9, 0.5625);

.marginFactory(10, 0.625);

.marginFactory(11, 0.6875);

.marginFactory(12, 0.75);

.marginFactory(13, 0.8125);

.marginFactory(15, 0.625 + (0.625 / 2));

.marginFactory(16, 1.01);

.marginFactory(20, 0.625 * 2);

.marginFactory(30, 0.625 * 3);

.marginFactory(40, 0.625 * 4);

.marginFactory(50, 0.625 * 5);

.marginFactory(60, 0.625 * 6);


.paddingFactory (@name, @size) {

  .pt-@{name}{
    padding-top: ~"@{size}rem";      
  }

  .pr-@{name} {
    padding-right: ~"@{size}rem";
  }

  .pb-@{name} {
    padding-bottom: ~"@{size}rem";
  }

  .pl-@{name} {
    padding-left: ~"@{size}rem";
  }

  .p-@{name} {
    padding: ~"@{size}rem"
  }

  .px-@{name} {
    padding-left: ~"@{size}rem";
    padding-right: ~"@{size}rem";
  }

  .py-@{name} {
    padding-top: ~"@{size}rem";
    padding-bottom: ~"@{size}rem"
  }

}

.paddingFactory(0, 0);

.paddingFactory(3, 0.1878);

.paddingFactory(4, 0.2504);

.paddingFactory(5, 0.313);

.paddingFactory(6, 0.3756);

.paddingFactory(7, 0.4382);

.paddingFactory(8, 0.500);

.paddingFactory(9, 0.5625);

.paddingFactory(10, 0.625);

.paddingFactory(11, 0.6875);

.paddingFactory(12, 0.75);

.paddingFactory(13, 0.8125);

.paddingFactory(15, 0.625 + (0.625 / 2));

.paddingFactory(16, 1.01);

.paddingFactory(20, 0.625 * 2);

.paddingFactory(25, 0.625 * 2.5);

.paddingFactory(30, 0.625 * 3);

.paddingFactory(40, 0.625 * 4);

.marginFactoryWithImportant (@name, @size) {

  .imt-@{name}{
    margin-top: ~"@{size}rem !important";      
  }

  .imr-@{name} {
    margin-right: ~"@{size}rem !important";
  }

  .imb-@{name} {
    margin-bottom: ~"@{size}rem !important";
  }

  .iml-@{name} {
    margin-left: ~"@{size}rem !important";
  }

}

.marginFactoryWithImportant(0, 0);

.marginFactoryWithImportant(5, 0.313);

.marginFactoryWithImportant(10, 0.625);

.marginFactoryWithImportant(15, 0.625 + (0.625 / 2));

.marginFactoryWithImportant(20, 0.625 * 2);

.marginFactoryWithImportant(30, 0.625 * 3);

.marginFactoryWithImportant(40, 0.625 * 4);