@import './../../styles/themes/theme.less';

.trigger {
  padding: 0 24px;
  font-size: 16px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: var(--primary-color-5);
  }
}

.header-layout {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.ant-menu-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.logo-container {
  padding: 0.3rem 0.9rem;
  max-height: 65px;
  text-align: center;
  &-img-expanded {
    height: 36px !important;
    transition: all 0.3s ease-in;
    animation: animation-width-collapsed 2.5s forwards;
  }
  &-img-collapsed {
    max-height: 40px;
    transition: all 0.3s ease-in;
    animation: animation-width-collapsed 1s forwards;
  }
}

.ant-image .logo-container-img-expanded {
  width: auto !important;
}

.ant-image .logo-container-img-collapsed {
  width: calc(80%);
}

@keyframes animation-width-collapsed {
  0% {
    opacity: 0;
    width: 0% !important;
  }
  100% {
    opacity: 1;
    width: 80% !important;
  }
}

@keyframes animation-width-expanded {
  0% {
    opacity: 0;
    background-color: transparent !important;
  }
  100% {
    opacity: 1;
    background-color: inherit;
  }
}
