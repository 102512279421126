.cost-per-sf-cell-wrap {
  gap: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--colorPrimaryBg);
  color: var(--colorPrimaryText);

  .cost-per-sf-cell-currency {
    color: var(--colorPrimaryBorderHover);
  }
}

.cost-cell-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  color: var(--colorTextSecondary);

  &.editable {
    border: 1px solid var(--colorBorderSecondary);
    border-radius: 2px;
  }

  .cost-cell-currency {
    color: var(--colorFill);
  }
}

.custom-expand-icon {
  margin-right: 1rem;
  font-size: 12px;
  color: var(--colorTextTertiary);

  &.custom-expand-icon-disabled {
    color: transparent;
  }
}

.budget-approvers-timeline .ant-timeline-item-tail {
  margin-top: 0.2rem;
}

.budget-approvers-timeline .ant-timeline-item-last {
  padding-bottom: 0px;
}

.ant-popover-content {
  max-width: 25em;
}

.budget-group-row {
  background: linear-gradient(0deg, var(--Colors-Neutral-Fill-colorFill, rgba(41, 45, 45, 0.15)) 0%, var(--Colors-Neutral-Fill-colorFill, rgba(41, 45, 45, 0.15)) 100%), var(--Colors-Neutral-Background-colorBgBase, #FCFDFA);
  position: sticky;
  top: 0;
  z-index: 3;
}
.budget-group-row-dark {
  background: linear-gradient(0deg, var(--Colors-Neutral-Fill-colorFill, rgba(252, 253, 250, 0.30)) 0%, var(--Colors-Neutral-Fill-colorFill, rgba(252, 253, 250, 0.30)) 100%), var(--Colors-Neutral-Background-colorBgBase, #292D2D);
  position: sticky;
  top: 0;
  z-index: 3;
}

.budget-category-row {
  background: linear-gradient(0deg, var(--Colors-Neutral-Fill-colorFillSecondary, rgba(41, 45, 45, 0.06)) 0%, var(--Colors-Neutral-Fill-colorFillSecondary, rgba(41, 45, 45, 0.06)) 100%), var(--Colors-Neutral-Background-colorBgBase, #FCFDFA);
  border-bottom: var(--Number, 1px) solid var(--Colors-Neutral-Fill-colorFillSecondary, rgba(41, 45, 45, 0.06));
  position: sticky;
  top: 47px;
  z-index: 2;
}
.budget-category-row-dark {
  background: linear-gradient(0deg, var(--Colors-Neutral-Fill-colorFillSecondary, rgba(252, 253, 250, 0.18)) 0%, var(--Colors-Neutral-Fill-colorFillSecondary, rgba(252, 253, 250, 0.18)) 100%), var(--Colors-Neutral-Background-colorBgBase, #292D2D);
  border-bottom: var(--Number, 1px) solid var(--Colors-Neutral-Fill-colorFillSecondary, rgba(41, 45, 45, 0.06));
  position: sticky;
  top: 47px;
  z-index: 2;
}

.editable-row {
  background-color: var(--colorBgNeutral);
  position: relative;
  z-index: 1;
}
