.button-windowinfo {
    color: #fff;
    background-color: var(--colorPrimary) !important;
    box-shadow: 0 2px 0 rgba(128, 137, 10, 0.11);
    padding: 4px 15px;
    border-radius: 2px;
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    line-height: 1.5714285714285714;
}

.ghost-button-windowinfo {
    color: var(--colorPrimary) !important;
    background-color: transparent;
    border-color: var(--colorPrimary) !important;
    box-shadow: none;
    padding: 4px 15px;
    border-radius: 2px;
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    line-height: 1.5714285714285714;
}
