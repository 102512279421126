/* ################ */
/* #### Overflow #### */

.overflow-hidden {
    overflow: hidden;
}
.overflow-auto	{
    overflow: auto;
}
.overflow-scroll	{
    overflow: scroll;
}
.overflow-y-auto { 
    overflow-y: auto 
}
.overflow-y-scroll { 
    overflow-y: scroll 
}

/* ################ */
/* #### Position #### */

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.sticky	{ 
    position: sticky; 
}


/* ################ */
/* #### Top, Right, Bottom, Left #### */

.top-0 {
    top: 0px;
}
.right-0 {
    right: 0px;
}
.bottom-0 {
    bottom: 0px;
}
.left-0 {
    left: 0px;
}

/* ################ */
/* #### Z-Index #### */

.z-0 { 
    z-index: 0; 
}
.z-10 { 
    z-index: 10; 
}
.z-20 { 
    z-index: 20; 
}
.z-30 { 
    z-index: 30; 
}
.z-40 { 
    z-index: 40; 
}
.z-50 { 
    z-index: 50; 
}
.z-auto	{ 
    z-index: auto; 
}

// Aspect Ratio
.aspect-auto {
    aspect-ratio: auto; 
}
.aspect-square {
    aspect-ratio: 1 / 1; 
}
.aspect-video {
    aspect-ratio: 16 / 9; 
}