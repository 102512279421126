.hover-text-current:hover {
    color: currentColor;
}

.text-color-original {
    color: var(--colorText);
}
.text-color-secondary {
    color: var(--colorSecondary);
}
.text-color-tertiary {
    color: var(--colorTeritary);
}
.text-color-quaternary {
    color: var(--colorQuaternary);
}

.text-color-primary {
    color: var(--colorPrimary);
}

.text-color-neutral-1 {
    color: var(--neutral-1);
}
.text-color-neutral-2 {
    color: var(--neutral-2);
}
.text-color-neutral-3 {
    color: var(--neutral-3);
}
.text-color-neutral-4 {
    color: var(--neutral-4);
}
.text-color-neutral-5 {
    color: var(--neutral-5);
}
.text-color-neutral-6 {
    color: var(--neutral-6);
}
.text-color-neutral-7 {
    color: var(--neutral-7);
}
.text-color-neutral-8 {
    color: var(--neutral-8);
}
.text-color-neutral-9 {
    color: var(--neutral-9);
}
.text-color-neutral-10 {
    color: var(--neutral-10);
}
.text-color-neutral-11 {
    color: var(--neutral-11);
}

.bg-color-neutral-1 {
    background-color: var(--neutral-1);
}
.bg-color-neutral-2 {
    background-color: var(--neutral-2);
}
.bg-color-neutral-3 {
    background-color: var(--neutral-3);
}
.bg-color-neutral-4 {
    background-color: var(--neutral-4);
}
.bg-color-neutral-5 {
    background-color: var(--neutral-5);
}
.bg-color-neutral-6 {
    background-color: var(--neutral-6);
}
.bg-color-neutral-7 {
    background-color: var(--neutral-7);
}
.bg-color-neutral-8 {
    background-color: var(--neutral-8);
}
.bg-color-neutral-9 {
    background-color: var(--neutral-9);
}
.bg-color-neutral-10 {
    background-color: var(--neutral-10);
}
.bg-color-neutral-11 {
    background-color: var(--neutral-11);
}