.admin-item-card {
    width: 400px;
    margin-bottom: 16px;

    .item-count {
        background: var(--colorFillSecondary);
        padding: 0px, 8px, 0px, 8px;
        color: var(--colorTextSecondary);
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        height: 20px;
        width: 24px;
        display: inline-block;
        border-radius: 100px;
        margin-left: 8px;
    }

    .extra-label {
        margin-right: 8px;
        cursor: pointer;
    }

    ul {
        padding-left: 15px;

        > div > li {
            padding: 4px 8px;
            margin-bottom: 4px;
        }

        .ant-tag {
            float: right;
        }

        &.editing {
            padding-left: 0;

            .ant-tag {
                float: none;
            }
        }

        .list-item-editable {
            display: flex;
            background-color: var(--colorBgContainer);
            border: 1px solid var(--colorFill);
            border-radius: 4px;
            padding: 4px 8px;
            margin-bottom: 4px;
            cursor: pointer;

            .handle-container {
                padding-top: 11px;
                margin-right: 8px;

                .handle-dot {
                    border-radius: 100px;
                    height: 6px;
                    width: 6px;
                    background-color: var(--colorFillSecondary);
                }
            }
            .description {
                padding: 5px 0;
                color: var(--colorTextSecondary);
            }
            .edit-container {
                display: none;
                flex: 1;
                text-align: right;
                padding: 5px 0;

                .edit-link {
                    color: var(--colorPrimary);
                    .anticon {
                        margin-right: 3px;
                    }
                }
            }

            &:hover {
                border-color: var(--colorPrimaryBorderHover);
                .edit-container {
                    display: block;
                }
            }
            &.selected {
                border-color: var(--colorPrimaryBorderHover);
                .edit-container {
                    display: block;
                }
                background-color: var(--colorPrimaryBg);
                .handle-container {
                    .handle-dot {
                        background-color: var(--colorPrimaryBgHover);
                    }
                }
                .description {
                    color: var(--colorPrimaryText);
                }
            }
        }
    }

    .spacer {
        border-top: 1px solid var(--colorFillSecondary);
    }
    .add-new-btn {
        margin-top: 10px;
        display: inline-block;
        cursor: pointer;
        color: var(--colorPrimary);
        margin-left: 20px;
    }
}
