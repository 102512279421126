/* ################ */
/* #### Height #### */
.h-0 {
  height: 0;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: calc(100vh / var(--zoom)); 
}
.h-min {
  height: min-content;
}
.h-max {
  height: max-content;
}
.h-fit {
  height: fit-content;
}

.min-w-max {
  min-width: max-content;
}

.min-h-screen	{ 
  min-height: 100vh; 
}

.min-w-full {
  min-width: 100%;
}

/* ################ */
/* #### Width #### */

.w-auto {
  width: auto;
}

.w-full { 
  width: 100%;
}	
.w-screen { 
  width: calc(100vw / var(--zoom));
}
.w-min	{
  width: min-content;
}
.w-max	{
  width: max-content;
}
.w-fit	{
  width: fit-content;
}
