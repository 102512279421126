.ant-menu-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}
.ant-menu-item-selected::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: var(--menu-item-bar-left-color, #fff);
}

.menu-item-selected-left.ant-menu-item-selected::after {
    left: 0;
}

.menu-item-selected-right.ant-menu-item-selected::after {
    right: 0;
}

