@import './../../../styles/themes/theme.less';

.wrapper-popover-menu {

    .ant-popover-inner {
        padding: 0 !important;
    }
    
    .ant-popover-placement-bottomRight {
        padding-top: 0px !important;
    }
    
    .ant-divider-horizontal {
        margin: 5px 0 !important;
    }

    .menu-content {
        .menu-item {
            line-height: 1.4;
            font-weight: 400;
            padding-left: 2.5px;
            font-size: @font-size-sm + 1px;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                padding-left: 0px;
                border-left: 2.5px solid var(--neutral-5);
                background-color: var(--neutral-4);
                text-shadow: 0.3px 0px 0px currentColor;
            }
        }
    }
}


