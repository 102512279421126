.cursor-pointer {
    cursor: pointer;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-no-drop {
    cursor: no-drop;
}
.cursor-copy {
    cursor: copy;
}
.cursor-default {
    cursor: default;
}

// Selecting

.select-none { 
    user-select: none; 
}

// Pointer Events

.pointer-events-none {
    pointer-events: none;
}