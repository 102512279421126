@import './../../styles/themes/theme.less';

.login {
  min-height: 100vh;
  transition: all 1 ease;
  &-container-card {
    display: flex;
    justify-content: center;
    transition: all 1 ease;
  }
  .ant-card {
    margin-top: 5%;
  }

  &-logo {
    display: flex;
    position: relative;
    width: 50px !important;
    margin: 20px auto 35px auto;
  }
  &-username {
    width: 100%;
  }
  .save-login-label, .forgot-password-label {
    font-size: 12px;
  } 
  .forgot-password-label {
    color: var(--primary-color-5) !important;
  }
  .button-login {
    width: 100%;
  }
  .divider {
    font-size: @fontsize-xs !important;
  }
  .button-sign-up {
    color: var(--primary-color-5);
    font-weight: bold;
  }
}
