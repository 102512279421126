@import "./../abstracts/vars.less";

/**
 * ----------------------------------------
 * animation shake
 * ----------------------------------------
 */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0.45rem);
  }
  75% {
    transform: translateX(-0.45rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

.error-animation {
  animation: shake 0.2s ease-in-out 0s 2;
}


/**
 * ----------------------------------------
 * animation grow
 * ----------------------------------------
 */
 @-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
}
@keyframes grow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
}

.animation-grow-in-hover:hover {
  animation: grow 0.1s linear forwards;
}

.generic-transition {
  animation-duration: 0.5s;
  transition: all 0.5s ease-out;
}

.generic-transition-ease-in {
  animation-duration: 0.5s;
  transition: all 0.5s ease-in;
}

.calculated-center {
  left: 50%;
  transform: translateX(-50%);
}

.calculated-left {
  @calculated-left: 3 * @factor-rem;
  left: @calculated-left;
}


.slide-in-right {
	-webkit-animation: slide-in-right 0.3s ease-out both infinite;
	        animation: slide-in-right 0.3s ease-out both infinite;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
