@import './../../styles/themes/theme.less';

.header-avatar {
    background: var(--tertiary-color-5);
    min-width: 230px !important;
}

.wrapper-popover {
    .ant-popover-arrow::before {
        background-color: var(--tertiary-color-5) !important;
    }
    .ant-popover-inner {
        padding: 0 !important;
    }
    
    .ant-popover-placement-bottomRight {
        padding-top: 0px !important;
    }
    
    .ant-divider-horizontal {
        margin: 5px 0 !important;
    }

    .menu-user-content {
        .avatar {
            position: relative;
            bottom: -35px;
        }
        .user-menu-item {
            line-height: 1.4;
            font-weight: 400;
            padding-left: 2.5px;
            font-size: @font-size-sm + 1px;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                padding-left: 0px;
                border-left: 2.5px solid var(--tertiary-color-6);
                background-color: var(--tertiary-color-2);
                font-weight: 500;
            }
        }
    }
}


