.border-t-0	{
    border-top-width: 0px;
}

.rounded-full {
    border-radius: 9999px
}

.border	{
    border-width: 1px;
}

.border-solid {
    border-style: solid;
}

.border-neutral-3 {
    border-color: var(--neutral-3, #F5F5F5);
}

.border-color-fill {
    border: 1px solid var(--colorFill);
}

.rounded-3 {
    border-radius: 3px;
}

.bordered-top-right-table .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    opacity: 0;
}

.border-container-focus {
    border: 1px solid #91D5FF;
    filter: drop-shadow(0px 0px 2px #91D5FF);
}