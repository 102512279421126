.admin-search-result {
    padding: 8px;
    border: 1px solid;
    border-radius: 4px;
    margin-bottom: 6px;
    cursor: pointer;
    border-color: var(--colorBorderSecondary);

    &:hover {
        border-color: var(--colorPrimaryBorderHover);
        background-color: var(--colorPrimaryBg);
    }

    .ant-badge {
        margin-right: 6px;
    }
    .action-card-name {
        display: inline-block;
        border-radius: 1000px;
        padding: 0 8px;
        line-height: 20px;
        height: 20px;
        font-size: 12px;
    }
}
