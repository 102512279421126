.template-lists-container {
    background: var(--colorBgBase);
    border: 1px solid var(--colorBorderSecondary);
    margin: 0.5rem;
    display: flex;
    max-width: 920px;
}
.panel-left {
    width: 460px;
    min-width: 460px;
    flex: 0;
    border-right: 1px solid var(--colorBorderSecondary);
}
.panel-right {
    width: 460px;
    min-width: 460px;
    flex: 0;
}
.panel-header {
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
}
.action-pill {
    flex: 0;
    display: flex;
    border-radius: 1rem;
    padding: 8px 10px;
    min-width: 160px;
    font-size: 12px;
}
.action-pill div {
    cursor: pointer;
}
.cost-group {
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    margin-bottom: 12px;
}
.cost-group-item {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.cost-group-item i {
    cursor: pointer;
}
.cost-group-item i.chevron-down {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    background-image: url(./../../assets/images/icons/chevron-down.svg);
    transform: rotate(-90deg);
}
.cost-group-item i.chevron-down.expanded {
    transform: rotate(0deg);
}
