.under-construction {
  background: #a5d401;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #E29424, #a5d401);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #E29424, #a5d401); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;

  &--poster {
    height: 300px;
    margin-left: 20px;
    align-self: flex-start;
    right: 12%;
    z-index: 10;
  }
  &--left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12%;
  }
  &--title {
    font-weight: 200 !important;
    color: #49596F;
    font-weight: bolder;
    font-size: 40px;
    margin-top: 15px;
    & b {
      font-weight: 300 !important;
    }
  }
  &--subtitle {
    font-weight: 300 !important;
    font-weight: 200;
    font-size: 14px;
    color: #656565;
  }
  &--status {
    color: #515a68;
    margin-top: 25px;
    pointer-events: none;
    &-button {
      margin-right: 15px;
      &__content{
        margin-left: 5px !important;
        margin-right: 5px !important;
        font-size: 12px !important;
        color: #656565;
      }
    }
    &-switch {
      pointer-events: none;
    }
  }
  &--card {
    position: relative;
    background-color: #ffffff;
    width: calc(100vw - 250px);
    height: calc(100vh - 200px);
    min-height: 675px;
    margin: 100px auto !important;
    border-radius: 15px;
    -webkit-box-shadow: 18px 14px 57px -17px rgba(0,0,0,0.58);
    -moz-box-shadow: 18px 14px 57px -17px rgba(0,0,0,0.58);
    box-shadow: 18px 14px 57px -17px rgba(0,0,0,0.58);
  }
  &--img {
    height: 550px;
    position: absolute;
    bottom: 0;
    right: 12%;
  }
  &--cloud-1 {
    position: absolute;
    height: 26px;
    top: 125px;
    left: 45px;
  }
  &--cloud-2 {  
    position: absolute;
    height: 32px;
    top: 38px;
    right: 590px;
    opacity: 0.75;
  }
  &--cloud-3 {
    position: absolute;
    height: 29px;
    top: 120px;
    right: 300px;
    opacity: 0.85;
  }
  &--cloud-4 {
    position: absolute;
    height: 40px;
    top: 45px;
    right: 50px;
    opacity: 0.90;
  }
}

@media (max-width: 1700px) {
  .under-construction {
    &--left-side {
      margin-left: 11%;
    }
    &--img {
      height: 500px !important;
      right: 11%;
    }
  }
}

@media (max-width: 1500px) {
  .under-construction {
    &--left-side {
      margin-left: 10%;
    }
    &--img {
      height: 450px !important;
      right: 10%;
    }
  }
}

@media (max-width: 1300px) {
  .under-construction {
    &--left-side {
      margin-left: 9%;
    }
    &--img {
      height: 410px !important;
      right: 9%;
    }
    &--cloud-2 {  
      right: 500px;
    }
  }
}

@media (max-width: 1200px) {
  .under-construction {
    &--cloud-2 {  
      display: none;
    }
    &--cloud-3 {
      height: 18px;
      top: 100px;
      right: 230px;
    }
  }
}



@media (max-width: 1100px) {
  .under-construction {
    &--card {
      width: calc(100vw - 150px);
    }
    &--left-side {
      margin-left: 7.5%;
    }
    &--img {
      height: 400px !important;
      right: 7.5%;
    }
  }
}
@media (max-width: 1025px) {
  .under-construction {
    &--left-side {
      margin-left: 6.5%;
    }
    &--img {
      height: 350px !important;
      right: 6.5%;
    }
  }
}

@media (max-width: 900px) {
  .under-construction {
    &--left-side {
      margin-left: 5.5%;
    }
    &--img {
      height: 320px !important;
      right: 5.5%;
    }
  }
}

@media (max-width: 800px) {
  .under-construction {
    &--left-side {
      margin-left: 4.5%;
    }
    &--img {
      height: 255px !important;
      right: 4.5%;
    }
  }
}

@media (max-width: 700px) {
  .under-construction {
    &--card {
      width: calc(100vw - 50px);
      height: calc(100vh - 70px);
      margin: 35px auto !important;
    }
    &--poster {
      align-self: center;
      margin: 0;
    }
    &--left-side {
      margin-left: 3%;
      align-items: center;
      margin: 0;
    }
    &--img {
      height: 240px !important;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    &--cloud-3 {
      height: 11px;
      top: 105px;
      right: 215px;
    }
  }
}

@media (max-width: 500px) {
  .under-construction {
    &--img {
      height: 220px !important;
    }
    &--cloud-3 {
      display: none;
    }
    &--cloud-4 {
      height: 25px;
      top: 40px;
      right: 28px;
    }
  }
  
}

@media (max-width: 450px) {
  .under-construction {
    &--img {
      height: 195px !important;
    }
    &--cloud-1 {
      height: 16px;
    }
  }
}

@media (max-width: 400px) {
  .under-construction {
    &--title {
      text-align: center;
      line-height: 1;
      margin-bottom: 15px;
      padding: 0 10px;
    }
    &--subtitle {
      text-align: center;
      padding: 0 10px;
    }
    &--img {
      height: 170px !important;
    }
    &--cloud-4 {
      display: none;
    }
  }
}

@media (max-width: 350px) {
  .under-construction {
    &--img {
      height: 150px !important;
    }
  }
}
