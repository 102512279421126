
.hidden {
    display: none;
}
.block {
    display: block;
}
.inline {
    display: inline; 
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.contents {
    display: contents; 
}