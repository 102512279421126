@fontsize-xs: 0.75rem;

.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-xs {
    font-size: @fontsize-xs;
    line-height: 1rem;
}
.text-tiny-075 {
    font-size: 0.75rem;
    line-height: 1;
}
.text-tiny-070 {
    font-size: 0.70rem;
    line-height: 1;
}
.text-tiny-065 {
    font-size: 0.65rem;
    line-height: 1;
}
.text-tiny-060 {
    font-size: 0.60rem;
    line-height: 1;
}
.text-tiny-055 {
    font-size: 0.55rem;
    line-height: 1;
}
.text-tiny-050 {
    font-size: 0.50rem;
    line-height: 1;
}
.text-tiny-045 {
    font-size: 0.45rem;
    line-height: 1;
}

.font-thin	{
    font-weight: 100;
}
.font-extralight	{
    font-weight: 200;
}
.font-light	{
    font-weight: 300;
}
.font-normal	{
    font-weight: 400;
}
.font-medium	{
    font-weight: 500;
}
.font-semibold	{
    font-weight: 600;
}
.font-bold	{
    font-weight: 700;
}
.font-extrabold	{
    font-weight: 800; 
}
.font-black {
    font-weight: 900;
}

.underline	{
    text-decoration-line: underline; 
}

.tracking-normal {
    letter-spacing: 0em; 
}

.capitalized {
    text-transform: capitalize;
}

.no-underline {
    text-decoration-line: none; 
}

// Text Decoration Color
.decoration-current	{
    text-decoration-color: currentColor; 
}
.decoration-inherit {
    text-decoration-color: inherit;
}

// Font Style 
.placeholder-italic *::placeholder {
    font-style: italic;
}


// Line height

.font-14-line-h-20 {
    line-height: 1.42857142857;
}
.font-14-line-h-22 {
    line-height: 1.57142857143;
}