.currencies-table {
    width: fit-content;
    border-radius: 0.75rem;

    .currencies-table-header, .currencies-table-row {
        display: flex;

        > div {
            line-height: 22px;
            font-weight: 500;
            font-size: 14px;
            padding: 2px 12px;
            margin: 10px 0px;
            color: rgba(0,0,0,0.85);
            border-left: 1px solid rgba(0,0,0,0.06);

            &:first-child {
                border-left: none;
            }

            &.name-field {
                width: 250px;
            }
            &.unit-field {
                width: 80px;
            }
            &.symbol-field {
                width: 80px;
            }
            &.isocode-field {
                width: 90px;
            }
            &.isonumber-field {
                width: 110px;
            }
            &.status-field {
                width: 200px;
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
    }

    .currencies-table-row {
        > div {
            border-left: 1px solid transparent;
            color: var(--colorText);
        }

        &.disabled {
            background-color: var(--colorFillSecondary);
            > div {
                color: var(--colorTextQuaternary);
            }
        }
    }
}
