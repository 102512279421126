.no-borders {
    border: 0 !important;
}

.b-gantt-task { 
    border-width : 1px;
    border-style: solid; 
}

// Fisrt Panel
div[id$='-lockedSubgrid'] .b-grid-row {
    border-bottom: 1px solid var(--colorBorderSecondary,#e9eaeb);
}

// Darker
div[id$='-lockedSubgrid'] .b-grid-row:nth-child(odd) {
    background-color: var(--neutral-2, #f2f2f2);
}

// Lighter
div[id$='-lockedSubgrid'] .b-grid-row:nth-child(even) {
    background-color: var(--neutral-1, #FAFAFA);
}

// Chart Side

div[id$='-normalSubgrid'] .b-grid-row {
    border-bottom: 1px solid var(--neutral-5,#d9d9d9);
}

// Darker
div[id$='-normalSubgrid'] .b-grid-row:nth-child(even) {
    background-color: var(--neutral-4, #f0f0f0);
}
// Lighter
div[id$='-normalSubgrid'] .b-grid-row:nth-child(odd) {
    background-color: var(--neutral-2, #fafafa);
}