.locationOverviewTabContainerXl {
   display: grid; 
   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr 1fr;
   grid-auto-flow: row;
   gap: 20px;

   #area-0 {
      grid-row-start: 1;
      grid-column-start: 1;
   
      grid-row-end: 3;
      grid-column-end: 2;
   }
   #area-1 {
      grid-row-start: 1;
      grid-column-start: 2;
   
      grid-row-end: 2;
      grid-column-end: 4;
   }
   #area-2 {
      grid-row-start: 2;
      grid-column-start: 2;
   
      grid-row-end: 3;
      grid-column-end: 3;
   }
   #area-3 {
      grid-row-start: 2;
      grid-column-start: 3;
   
      grid-row-end: 3;
      grid-column-end: 4;
   }
}

.locationOverviewTabContainerSM {
   display: grid; 

   grid-template-rows: 1fr 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   
   gap: 20px;

   #area-0 {
      grid-row-start: 1;
      grid-column-start: 1;
   
      grid-row-end: 2;
      grid-column-end: 3;
      
   }
   #area-1 {   
      grid-row-start: 2;
      grid-column-start: 1;
   
      grid-row-end: 3;
      grid-column-end: 3;
      
   }
   #area-2 {   
      grid-row-start: 3;
      grid-column-start: 1;
   
      grid-row-end: 4;
      grid-column-end: 2;
      
   }
   #area-3 {   
      grid-row-start: 3;
      grid-column-start: 2;
   
      grid-row-end: 4;
      grid-column-end: 3;
      
   }
}
  
.locationOverviewTabContainerXS {
   display: grid; 

   grid-template-rows: 1fr 1fr 1fr 1fr;
   grid-template-columns: 1fr;
   
   gap: 20px;

   #area-0 {
      grid-row-start: 1;
      grid-column-start: 1;
   
      grid-row-end: 2;
      grid-column-end: 2;
      
   }
   #area-1 {   
      grid-row-start: 2;
      grid-column-start: 1;
   
      grid-row-end: 3;
      grid-column-end: 2;
      
   }
   #area-2 {   
      grid-row-start: 3;
      grid-column-start: 1;
   
      grid-row-end: 4;
      grid-column-end: 2;
      
   }
   #area-3 {   
      grid-row-start: 4;
      grid-column-start: 1;
   
      grid-row-end: 5;
      grid-column-end: 2;
      
   }
}



