.app-table {
    tr {
        td:first-child {
            border-left: 2.5px solid transparent;
        }
        &:hover td:first-child {
            border-left: 2.5px solid var(--primary-color-7) !important;
        }
    }
    .table-with-selector {
        .ant-table-cell-row-hover {
            cursor: pointer;
            background: var(--primary-color-0) !important;
            color: var(--primary-color-8) !important;
            text-shadow: 0.3px 0px 0px var(--primary-color-8);
        }
    }
}

.table-caption-top {
    .ant-table-header {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .ant-table-thead .ant-table-cell:not(:last-child) {
        border-inline-end: 0 !important;
    }
    .ant-table-tbody .ant-table-cell {
        border-bottom: 0;
    }
    .ant-table-tbody .ant-table-cell:not(:last-child) {
        border-inline-end: 0 !important;
    }
    tr th:nth-child(1),
    td:nth-child(1) {
        padding-left: 1.09rem !important;
    }
    .ant-table-thead .ant-table-cell {
        border-start-end-radius: unset !important;
        border-radius: 0 !important;
    }
    .ant-table-body {
        overflow-y: auto !important;
    }
    .ant-table-footer {
        background: transparent;
        padding: 3px !important;
    }
    .ant-table-cell-row-hover {
        background-color: var(--bg-hover-row) !important;
    }
}

.hover-in-row {
    &:hover {
        cursor: pointer;
        & .icon-show-in-hover {
            opacity: 1;
        }
    }
    .icon-show-in-hover {
        opacity: 0;
    }
}

.bordered-custom-table .ant-table-container {
    border: 1px solid var(--colorBorderSecondary) !important;
}

.bordered-top-right-table .ant-table-container {
    border: 1px solid var(--colorBorderSecondary) !important;
    border-start-start-radius: 4px;
    .ant-table-cell {
        border-start-start-radius: 4px;
    }
}

.border-radius-8-custom-table .ant-table {
    border-radius: 8px;
    border: 1px solid var(--colorBorderSecondary);
    .ant-table-tbody > tr:last-child > td {
        border-bottom: none;
      }
}

