.template-edit {
    height: 100%;
    overflow: hidden;
    
    .deliverables-list-title {
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        flex: 1;
    }
    .deliverables-list-container {
        padding: 1rem;
        overflow: scroll;
        height: calc(100% - 165px);
    }
}
.deliverable-card {
    border-width: 1px;
    border-radius: 0.5rem;
    background-color: var(--colorBgBase);
    border-color: var(--colorBorderSecondary);
    border-style: solid;
    transition: border-color 0.25s linear, background-color 0.25s linear;
    min-width: 300px;
    min-height: 95px;
    display: flex;
    margin-bottom: 0.5rem;

    .left-col {
        padding: 1rem;
        flex: 1;

        .title-row {
            display: flex;
            flex: 1;

            .left-col-title {
                font-size: 16px;
                line-height: 24px;
                flex: 1;
            }
            .left-col-start {
                flex: 0;
            }
        }
        .discipline-row {
            margin-top: 0.5rem;
            font-size: 12px;
            line-height: 20px;

            span {
                font-weight: 700;
            }
        }
        .role-row {
            font-size: 12px;
            line-height: 20px;

            span {
                font-weight: 700;
            }
        }
        .already-on-template-msg {
            margin: 0.25rem -1rem -1rem -1rem;
            padding: 0.25rem;
            text-align: center;
            border-radius: 0 0 8px 8px;
            display: none;
        }
        .add-to-template {
            border-top: 1px solid var(--colorBorderSecondary);
            padding-top: 0.5rem;
            margin-top: 0.5rem;
            display: none;

            button {
                flex: 1;
            }
        }
        .opening-milestone {
            background-color: var(--colorFillSecondary);
            padding: 0.25rem 0.5rem;
            color: var(--colorTextTertiary);
            border-radius: 0.25rem;
            display: inline-block;
            margin-top: 10px;
        }
    }
    .right-col {
        min-width: 24px;
        flex: 0;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: grab;
    }

    &.already-added {
        .right-col {
            display: none;
        }
        .left-col {
            .title-row, .discipline-row, .role-row, .opening-milestone {
                opacity: 0.6;
            }
            .already-on-template-msg {
                display: block;
            }
            .add-to-template {
                display: none;
            }
        }
    }
    &:not(.already-added) {
        &:hover {
            background-color: var(--colorPrimaryBg) !important;
            border-color: var(--colorPrimaryBorderHover) !important;
            .left-col {
                .add-to-template {
                    display: flex;
                }
            }
        }
    }
}
.deliverable-in-list {
    border-bottom: 1px solid var(--colorBorderSecondary);

    .deliverable-data-row {
        display: flex;

        .deliverable-cell {
            padding: 0 16px;
            margin: 16px 0;
            border-left: 1px solid transparent;
            white-space: nowrap;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
            }

            &:first-child {
                border-left: 0;
            }
            &.cell-handle {
                width: 38px;
                cursor: grab;
            }
            &.cell-title {
                border-color: var(--colorBorderSecondary);
            }
            &.cell-order {
                width: 72px;
            }
            &.cell-duration {
                width: 93px;
            }
            &.cell-dependency {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.cell-phase {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.cell-approval {
                width: 95px;
            }
        }
    }

    &.deliverables-header {
        .deliverable-data-row {
            .deliverable-cell {
                margin: 16px 0;
                padding: 0 16px;
                border-color: var(--colorBorderSecondary);
                font-weight: 600;
                font-size: 14px;
                line-height: 1.5714285714285714;

                &:first-child {
                    border-left: 0;
                }
                &.cell-handle {
                    width: 38px;
                }
                &.cell-order {
                    width: 72px;
                }
                &.cell-duration {
                    width: 93px;
                }
                &.cell-dependency {
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &.cell-phase {
                    width: 100px;
                }
                &.cell-approval {
                    width: 95px;
                }
            }
        }   
    }
}
.dot {
    height: 4px;
    width: 4px;
    border-radius: 1000px;
}
