.ant-card-cover-reset .ant-card-cover {
    margin-top: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.ant-card.ant-card-no-body .ant-card-body {
    display: none;
}
.ant-card.admin-card {
    width: 400px;
    margin-bottom: 10px;
}