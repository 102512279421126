.description_punch_list_item_rich_text .editor-input-viewer {
  color: var(--neutral-8);
  padding: 0px !important;
}

.description_punch_list_item_rich_text_read_only .editor-input-viewer {
  color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
  line-height: 22px;
  padding: 0px !important;
}
