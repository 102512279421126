/**
 * ----------------------------------------
 * animation shake
 * ----------------------------------------
 */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0.45rem);
  }
  75% {
    transform: translateX(-0.45rem);
  }
  100% {
    transform: translateX(0rem);
  }
}
.error-animation {
  animation: shake 0.2s ease-in-out 0s 2;
}
/**
 * ----------------------------------------
 * animation grow
 * ----------------------------------------
 */
@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
}
@keyframes grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
}
.animation-grow-in-hover:hover {
  animation: grow 0.1s linear forwards;
}
.generic-transition {
  animation-duration: 0.5s;
  transition: all 0.5s ease-out;
}
.generic-transition-ease-in {
  animation-duration: 0.5s;
  transition: all 0.5s ease-in;
}
.calculated-center {
  left: 50%;
  transform: translateX(-50%);
}
.calculated-left {
  left: 1.875rem;
}
.slide-in-right {
  -webkit-animation: slide-in-right 0.3s ease-out both infinite;
  animation: slide-in-right 0.3s ease-out both infinite;
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.border-t-0 {
  border-top-width: 0px;
}
.rounded-full {
  border-radius: 9999px;
}
.border {
  border-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-neutral-3 {
  border-color: var(--neutral-3, #F5F5F5);
}
.border-color-fill {
  border: 1px solid var(--colorFill);
}
.rounded-3 {
  border-radius: 3px;
}
.bordered-top-right-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  opacity: 0;
}
.border-container-focus {
  border: 1px solid #91D5FF;
  filter: drop-shadow(0px 0px 2px #91D5FF);
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQw.ttf) format('truetype');
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-copy {
  cursor: copy;
}
.cursor-default {
  cursor: default;
}
.select-none {
  user-select: none;
}
.pointer-events-none {
  pointer-events: none;
}
.hover-text-current:hover {
  color: currentColor;
}
.text-color-original {
  color: var(--colorText);
}
.text-color-secondary {
  color: var(--colorSecondary);
}
.text-color-tertiary {
  color: var(--colorTeritary);
}
.text-color-quaternary {
  color: var(--colorQuaternary);
}
.text-color-primary {
  color: var(--colorPrimary);
}
.text-color-neutral-1 {
  color: var(--neutral-1);
}
.text-color-neutral-2 {
  color: var(--neutral-2);
}
.text-color-neutral-3 {
  color: var(--neutral-3);
}
.text-color-neutral-4 {
  color: var(--neutral-4);
}
.text-color-neutral-5 {
  color: var(--neutral-5);
}
.text-color-neutral-6 {
  color: var(--neutral-6);
}
.text-color-neutral-7 {
  color: var(--neutral-7);
}
.text-color-neutral-8 {
  color: var(--neutral-8);
}
.text-color-neutral-9 {
  color: var(--neutral-9);
}
.text-color-neutral-10 {
  color: var(--neutral-10);
}
.text-color-neutral-11 {
  color: var(--neutral-11);
}
.bg-color-neutral-1 {
  background-color: var(--neutral-1);
}
.bg-color-neutral-2 {
  background-color: var(--neutral-2);
}
.bg-color-neutral-3 {
  background-color: var(--neutral-3);
}
.bg-color-neutral-4 {
  background-color: var(--neutral-4);
}
.bg-color-neutral-5 {
  background-color: var(--neutral-5);
}
.bg-color-neutral-6 {
  background-color: var(--neutral-6);
}
.bg-color-neutral-7 {
  background-color: var(--neutral-7);
}
.bg-color-neutral-8 {
  background-color: var(--neutral-8);
}
.bg-color-neutral-9 {
  background-color: var(--neutral-9);
}
.bg-color-neutral-10 {
  background-color: var(--neutral-10);
}
.bg-color-neutral-11 {
  background-color: var(--neutral-11);
}
/* ################ */
/* #### Overflow #### */
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
/* ################ */
/* #### Position #### */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}
/* ################ */
/* #### Top, Right, Bottom, Left #### */
.top-0 {
  top: 0px;
}
.right-0 {
  right: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
/* ################ */
/* #### Z-Index #### */
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-auto {
  z-index: auto;
}
.aspect-auto {
  aspect-ratio: auto;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.hidden {
  display: none;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.opacity-0 {
  opacity: 0;
}
.opacity-5 {
  opacity: 0.05;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-95 {
  opacity: 0.95;
}
.opacity-100 {
  opacity: 1;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.self-baseline {
  align-self: baseline;
}
.ant-form-item-explain-error {
  font-size: 10px;
  margin-top: 3px;
}
.generic-shadow-left {
  box-shadow: -5px 4px 11px rgba(0, 0, 0, 0.15);
}
.container-shadow-bottom {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
}
.mt-0 {
  margin-top: 0rem;
}
.mr-0 {
  margin-right: 0rem;
}
.mb-0 {
  margin-bottom: 0rem;
}
.ml-0 {
  margin-left: 0rem;
}
.m-0 {
  margin: 0rem;
}
.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}
.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.mt-3 {
  margin-top: 0.1878rem;
}
.mr-3 {
  margin-right: 0.1878rem;
}
.mb-3 {
  margin-bottom: 0.1878rem;
}
.ml-3 {
  margin-left: 0.1878rem;
}
.m-3 {
  margin: 0.1878rem;
}
.mx-3 {
  margin-left: 0.1878rem;
  margin-right: 0.1878rem;
}
.my-3 {
  margin-top: 0.1878rem;
  margin-bottom: 0.1878rem;
}
.mt-4 {
  margin-top: 0.2504rem;
}
.mr-4 {
  margin-right: 0.2504rem;
}
.mb-4 {
  margin-bottom: 0.2504rem;
}
.ml-4 {
  margin-left: 0.2504rem;
}
.m-4 {
  margin: 0.2504rem;
}
.mx-4 {
  margin-left: 0.2504rem;
  margin-right: 0.2504rem;
}
.my-4 {
  margin-top: 0.2504rem;
  margin-bottom: 0.2504rem;
}
.mt-5 {
  margin-top: 0.313rem;
}
.mr-5 {
  margin-right: 0.313rem;
}
.mb-5 {
  margin-bottom: 0.313rem;
}
.ml-5 {
  margin-left: 0.313rem;
}
.m-5 {
  margin: 0.313rem;
}
.mx-5 {
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}
.my-5 {
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
}
.mt-6 {
  margin-top: 0.3756rem;
}
.mr-6 {
  margin-right: 0.3756rem;
}
.mb-6 {
  margin-bottom: 0.3756rem;
}
.ml-6 {
  margin-left: 0.3756rem;
}
.m-6 {
  margin: 0.3756rem;
}
.mx-6 {
  margin-left: 0.3756rem;
  margin-right: 0.3756rem;
}
.my-6 {
  margin-top: 0.3756rem;
  margin-bottom: 0.3756rem;
}
.mt-7 {
  margin-top: 0.4382rem;
}
.mr-7 {
  margin-right: 0.4382rem;
}
.mb-7 {
  margin-bottom: 0.4382rem;
}
.ml-7 {
  margin-left: 0.4382rem;
}
.m-7 {
  margin: 0.4382rem;
}
.mx-7 {
  margin-left: 0.4382rem;
  margin-right: 0.4382rem;
}
.my-7 {
  margin-top: 0.4382rem;
  margin-bottom: 0.4382rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.m-8 {
  margin: 0.5rem;
}
.mx-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mt-9 {
  margin-top: 0.5625rem;
}
.mr-9 {
  margin-right: 0.5625rem;
}
.mb-9 {
  margin-bottom: 0.5625rem;
}
.ml-9 {
  margin-left: 0.5625rem;
}
.m-9 {
  margin: 0.5625rem;
}
.mx-9 {
  margin-left: 0.5625rem;
  margin-right: 0.5625rem;
}
.my-9 {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem;
}
.mt-10 {
  margin-top: 0.625rem;
}
.mr-10 {
  margin-right: 0.625rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.ml-10 {
  margin-left: 0.625rem;
}
.m-10 {
  margin: 0.625rem;
}
.mx-10 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.my-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.mt-11 {
  margin-top: 0.6875rem;
}
.mr-11 {
  margin-right: 0.6875rem;
}
.mb-11 {
  margin-bottom: 0.6875rem;
}
.ml-11 {
  margin-left: 0.6875rem;
}
.m-11 {
  margin: 0.6875rem;
}
.mx-11 {
  margin-left: 0.6875rem;
  margin-right: 0.6875rem;
}
.my-11 {
  margin-top: 0.6875rem;
  margin-bottom: 0.6875rem;
}
.mt-12 {
  margin-top: 0.75rem;
}
.mr-12 {
  margin-right: 0.75rem;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.ml-12 {
  margin-left: 0.75rem;
}
.m-12 {
  margin: 0.75rem;
}
.mx-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mt-13 {
  margin-top: 0.8125rem;
}
.mr-13 {
  margin-right: 0.8125rem;
}
.mb-13 {
  margin-bottom: 0.8125rem;
}
.ml-13 {
  margin-left: 0.8125rem;
}
.m-13 {
  margin: 0.8125rem;
}
.mx-13 {
  margin-left: 0.8125rem;
  margin-right: 0.8125rem;
}
.my-13 {
  margin-top: 0.8125rem;
  margin-bottom: 0.8125rem;
}
.mt-15 {
  margin-top: 0.9375rem;
}
.mr-15 {
  margin-right: 0.9375rem;
}
.mb-15 {
  margin-bottom: 0.9375rem;
}
.ml-15 {
  margin-left: 0.9375rem;
}
.m-15 {
  margin: 0.9375rem;
}
.mx-15 {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}
.my-15 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.mt-16 {
  margin-top: 1.01rem;
}
.mr-16 {
  margin-right: 1.01rem;
}
.mb-16 {
  margin-bottom: 1.01rem;
}
.ml-16 {
  margin-left: 1.01rem;
}
.m-16 {
  margin: 1.01rem;
}
.mx-16 {
  margin-left: 1.01rem;
  margin-right: 1.01rem;
}
.my-16 {
  margin-top: 1.01rem;
  margin-bottom: 1.01rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.mr-20 {
  margin-right: 1.25rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.ml-20 {
  margin-left: 1.25rem;
}
.m-20 {
  margin: 1.25rem;
}
.mx-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mt-30 {
  margin-top: 1.875rem;
}
.mr-30 {
  margin-right: 1.875rem;
}
.mb-30 {
  margin-bottom: 1.875rem;
}
.ml-30 {
  margin-left: 1.875rem;
}
.m-30 {
  margin: 1.875rem;
}
.mx-30 {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}
.my-30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.mt-40 {
  margin-top: 2.5rem;
}
.mr-40 {
  margin-right: 2.5rem;
}
.mb-40 {
  margin-bottom: 2.5rem;
}
.ml-40 {
  margin-left: 2.5rem;
}
.m-40 {
  margin: 2.5rem;
}
.mx-40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.my-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mt-50 {
  margin-top: 3.125rem;
}
.mr-50 {
  margin-right: 3.125rem;
}
.mb-50 {
  margin-bottom: 3.125rem;
}
.ml-50 {
  margin-left: 3.125rem;
}
.m-50 {
  margin: 3.125rem;
}
.mx-50 {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}
.my-50 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
.mt-60 {
  margin-top: 3.75rem;
}
.mr-60 {
  margin-right: 3.75rem;
}
.mb-60 {
  margin-bottom: 3.75rem;
}
.ml-60 {
  margin-left: 3.75rem;
}
.m-60 {
  margin: 3.75rem;
}
.mx-60 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}
.my-60 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
.pt-0 {
  padding-top: 0rem;
}
.pr-0 {
  padding-right: 0rem;
}
.pb-0 {
  padding-bottom: 0rem;
}
.pl-0 {
  padding-left: 0rem;
}
.p-0 {
  padding: 0rem;
}
.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}
.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.pt-3 {
  padding-top: 0.1878rem;
}
.pr-3 {
  padding-right: 0.1878rem;
}
.pb-3 {
  padding-bottom: 0.1878rem;
}
.pl-3 {
  padding-left: 0.1878rem;
}
.p-3 {
  padding: 0.1878rem;
}
.px-3 {
  padding-left: 0.1878rem;
  padding-right: 0.1878rem;
}
.py-3 {
  padding-top: 0.1878rem;
  padding-bottom: 0.1878rem;
}
.pt-4 {
  padding-top: 0.2504rem;
}
.pr-4 {
  padding-right: 0.2504rem;
}
.pb-4 {
  padding-bottom: 0.2504rem;
}
.pl-4 {
  padding-left: 0.2504rem;
}
.p-4 {
  padding: 0.2504rem;
}
.px-4 {
  padding-left: 0.2504rem;
  padding-right: 0.2504rem;
}
.py-4 {
  padding-top: 0.2504rem;
  padding-bottom: 0.2504rem;
}
.pt-5 {
  padding-top: 0.313rem;
}
.pr-5 {
  padding-right: 0.313rem;
}
.pb-5 {
  padding-bottom: 0.313rem;
}
.pl-5 {
  padding-left: 0.313rem;
}
.p-5 {
  padding: 0.313rem;
}
.px-5 {
  padding-left: 0.313rem;
  padding-right: 0.313rem;
}
.py-5 {
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
}
.pt-6 {
  padding-top: 0.3756rem;
}
.pr-6 {
  padding-right: 0.3756rem;
}
.pb-6 {
  padding-bottom: 0.3756rem;
}
.pl-6 {
  padding-left: 0.3756rem;
}
.p-6 {
  padding: 0.3756rem;
}
.px-6 {
  padding-left: 0.3756rem;
  padding-right: 0.3756rem;
}
.py-6 {
  padding-top: 0.3756rem;
  padding-bottom: 0.3756rem;
}
.pt-7 {
  padding-top: 0.4382rem;
}
.pr-7 {
  padding-right: 0.4382rem;
}
.pb-7 {
  padding-bottom: 0.4382rem;
}
.pl-7 {
  padding-left: 0.4382rem;
}
.p-7 {
  padding: 0.4382rem;
}
.px-7 {
  padding-left: 0.4382rem;
  padding-right: 0.4382rem;
}
.py-7 {
  padding-top: 0.4382rem;
  padding-bottom: 0.4382rem;
}
.pt-8 {
  padding-top: 0.5rem;
}
.pr-8 {
  padding-right: 0.5rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.pl-8 {
  padding-left: 0.5rem;
}
.p-8 {
  padding: 0.5rem;
}
.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-9 {
  padding-top: 0.5625rem;
}
.pr-9 {
  padding-right: 0.5625rem;
}
.pb-9 {
  padding-bottom: 0.5625rem;
}
.pl-9 {
  padding-left: 0.5625rem;
}
.p-9 {
  padding: 0.5625rem;
}
.px-9 {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.py-9 {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}
.pt-10 {
  padding-top: 0.625rem;
}
.pr-10 {
  padding-right: 0.625rem;
}
.pb-10 {
  padding-bottom: 0.625rem;
}
.pl-10 {
  padding-left: 0.625rem;
}
.p-10 {
  padding: 0.625rem;
}
.px-10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.pt-11 {
  padding-top: 0.6875rem;
}
.pr-11 {
  padding-right: 0.6875rem;
}
.pb-11 {
  padding-bottom: 0.6875rem;
}
.pl-11 {
  padding-left: 0.6875rem;
}
.p-11 {
  padding: 0.6875rem;
}
.px-11 {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}
.py-11 {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
}
.pt-12 {
  padding-top: 0.75rem;
}
.pr-12 {
  padding-right: 0.75rem;
}
.pb-12 {
  padding-bottom: 0.75rem;
}
.pl-12 {
  padding-left: 0.75rem;
}
.p-12 {
  padding: 0.75rem;
}
.px-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pt-13 {
  padding-top: 0.8125rem;
}
.pr-13 {
  padding-right: 0.8125rem;
}
.pb-13 {
  padding-bottom: 0.8125rem;
}
.pl-13 {
  padding-left: 0.8125rem;
}
.p-13 {
  padding: 0.8125rem;
}
.px-13 {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
}
.py-13 {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
}
.pt-15 {
  padding-top: 0.9375rem;
}
.pr-15 {
  padding-right: 0.9375rem;
}
.pb-15 {
  padding-bottom: 0.9375rem;
}
.pl-15 {
  padding-left: 0.9375rem;
}
.p-15 {
  padding: 0.9375rem;
}
.px-15 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.py-15 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.pt-16 {
  padding-top: 1.01rem;
}
.pr-16 {
  padding-right: 1.01rem;
}
.pb-16 {
  padding-bottom: 1.01rem;
}
.pl-16 {
  padding-left: 1.01rem;
}
.p-16 {
  padding: 1.01rem;
}
.px-16 {
  padding-left: 1.01rem;
  padding-right: 1.01rem;
}
.py-16 {
  padding-top: 1.01rem;
  padding-bottom: 1.01rem;
}
.pt-20 {
  padding-top: 1.25rem;
}
.pr-20 {
  padding-right: 1.25rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.pl-20 {
  padding-left: 1.25rem;
}
.p-20 {
  padding: 1.25rem;
}
.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.pt-25 {
  padding-top: 1.5625rem;
}
.pr-25 {
  padding-right: 1.5625rem;
}
.pb-25 {
  padding-bottom: 1.5625rem;
}
.pl-25 {
  padding-left: 1.5625rem;
}
.p-25 {
  padding: 1.5625rem;
}
.px-25 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.py-25 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}
.pt-30 {
  padding-top: 1.875rem;
}
.pr-30 {
  padding-right: 1.875rem;
}
.pb-30 {
  padding-bottom: 1.875rem;
}
.pl-30 {
  padding-left: 1.875rem;
}
.p-30 {
  padding: 1.875rem;
}
.px-30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.py-30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.pt-40 {
  padding-top: 2.5rem;
}
.pr-40 {
  padding-right: 2.5rem;
}
.pb-40 {
  padding-bottom: 2.5rem;
}
.pl-40 {
  padding-left: 2.5rem;
}
.p-40 {
  padding: 2.5rem;
}
.px-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.imt-0 {
  margin-top: 0rem !important;
}
.imr-0 {
  margin-right: 0rem !important;
}
.imb-0 {
  margin-bottom: 0rem !important;
}
.iml-0 {
  margin-left: 0rem !important;
}
.imt-5 {
  margin-top: 0.313rem !important;
}
.imr-5 {
  margin-right: 0.313rem !important;
}
.imb-5 {
  margin-bottom: 0.313rem !important;
}
.iml-5 {
  margin-left: 0.313rem !important;
}
.imt-10 {
  margin-top: 0.625rem !important;
}
.imr-10 {
  margin-right: 0.625rem !important;
}
.imb-10 {
  margin-bottom: 0.625rem !important;
}
.iml-10 {
  margin-left: 0.625rem !important;
}
.imt-15 {
  margin-top: 0.9375rem !important;
}
.imr-15 {
  margin-right: 0.9375rem !important;
}
.imb-15 {
  margin-bottom: 0.9375rem !important;
}
.iml-15 {
  margin-left: 0.9375rem !important;
}
.imt-20 {
  margin-top: 1.25rem !important;
}
.imr-20 {
  margin-right: 1.25rem !important;
}
.imb-20 {
  margin-bottom: 1.25rem !important;
}
.iml-20 {
  margin-left: 1.25rem !important;
}
.imt-30 {
  margin-top: 1.875rem !important;
}
.imr-30 {
  margin-right: 1.875rem !important;
}
.imb-30 {
  margin-bottom: 1.875rem !important;
}
.iml-30 {
  margin-left: 1.875rem !important;
}
.imt-40 {
  margin-top: 2.5rem !important;
}
.imr-40 {
  margin-right: 2.5rem !important;
}
.imb-40 {
  margin-bottom: 2.5rem !important;
}
.iml-40 {
  margin-left: 2.5rem !important;
}
/* ################ */
/* #### Height #### */
.h-0 {
  height: 0;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: calc(100vh / var(--zoom));
}
.h-min {
  height: min-content;
}
.h-max {
  height: max-content;
}
.h-fit {
  height: fit-content;
}
.min-w-max {
  min-width: max-content;
}
.min-h-screen {
  min-height: 100vh;
}
.min-w-full {
  min-width: 100%;
}
/* ################ */
/* #### Width #### */
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: calc(100vw / var(--zoom));
}
.w-min {
  width: min-content;
}
.w-max {
  width: max-content;
}
.w-fit {
  width: fit-content;
}
.scale-90 {
  transform: scale(0.9);
}
.transition-all-1-ease {
  transition: all 1 ease;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-tiny-075 {
  font-size: 0.75rem;
  line-height: 1;
}
.text-tiny-070 {
  font-size: 0.7rem;
  line-height: 1;
}
.text-tiny-065 {
  font-size: 0.65rem;
  line-height: 1;
}
.text-tiny-060 {
  font-size: 0.6rem;
  line-height: 1;
}
.text-tiny-055 {
  font-size: 0.55rem;
  line-height: 1;
}
.text-tiny-050 {
  font-size: 0.5rem;
  line-height: 1;
}
.text-tiny-045 {
  font-size: 0.45rem;
  line-height: 1;
}
.font-thin {
  font-weight: 100;
}
.font-extralight {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.underline {
  text-decoration-line: underline;
}
.tracking-normal {
  letter-spacing: 0em;
}
.capitalized {
  text-transform: capitalize;
}
.no-underline {
  text-decoration-line: none;
}
.decoration-current {
  text-decoration-color: currentColor;
}
.decoration-inherit {
  text-decoration-color: inherit;
}
.placeholder-italic *::placeholder {
  font-style: italic;
}
.font-14-line-h-20 {
  line-height: 1.42857143;
}
.font-14-line-h-22 {
  line-height: 1.57142857;
}
.ant-card-cover-reset .ant-card-cover {
  margin-top: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.ant-card.ant-card-no-body .ant-card-body {
  display: none;
}
.ant-card.admin-card {
  width: 400px;
  margin-bottom: 10px;
}
.ant-select-item-option-selected .item-selected-make-bolder {
  font-weight: 600;
}
.gm-style-iw-t .gm-ui-hover-effect {
  outline: 0 !important;
}
.rootAppearence .ant-modal-content {
  background-color: transparent;
}
.rootAppearence .ant-modal-header {
  background-color: transparent;
}
.ant-tabs {
  height: 100%;
}
.ant-tabs-tab {
  margin-right: 3px !important;
}
.custom-tabs .ant-tabs-content-holder {
  height: 100%;
}
.ant-tabs-content {
  height: 100%;
}
.bg-transparent .ant-timeline-item-head {
  background-color: transparent;
}
.fullMaxWidth {
  max-width: 100% !important;
}
.custom-skeleton {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
}
.custom-skeleton-image {
  width: 100% !important;
  height: 100% !important;
}
.ant-btn-text.custom-btn-text:hover {
  color: var(--primary-color-6);
  border: 1px solid var(--primary-color-2);
}
.singleNotification .ant-notification-notice-message {
  margin-bottom: 0 !important;
}
.ant-upload-disabled {
  opacity: 0.5;
}
