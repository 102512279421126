.notification-clipboard {
  width: 100%;
  padding: 1rem 1.4rem 1rem 1rem;
  display: flex;
  justify-content: center;  
  align-items: center;
  .ant-notification-notice-message {
    margin-bottom: 2px !important;
  }
}
