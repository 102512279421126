.admin-cost-groups-panel {
    margin: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 88px);
    border-radius: 0.5rem;
    display: flex;
    height: auto;

    .cost-groups-column {
        padding: 1.5rem;
        flex: 1;
        height: auto;

        .cost-groups-header {
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            height: 42px;

            .title-text {
                font-weight: 500;
                font-size: 15px;
                white-space: nowrap;
            }
        }
    }
}
.draggable-item {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .drag-handle {
        cursor: move;
    }

    .edit-link {
        visibility: hidden;
    }

    &:hover {
        .edit-link {
            visibility: visible;
        }
    }
}
