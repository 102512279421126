/* The popup bubble styling. */
.custom-popup-bubble {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: var(--colorBgBase, #fff);
    // padding: 5px;
    border-radius: 8px;
    font-family: sans-serif;
    overflow-y: auto;
    max-height: 92;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
  }
  
  /* The parent of the bubble. A zero-height div at the top of the tip. */
  .custom-popup-bubble-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 40px;
    left: 0;
    border-width: 0;
  }
  
  /* This element draws the tip. */
  .custom-popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid var(--colorBgBase, #fff);
  }
  
  /* JavaScript will position this div at the bottom of the popup tip. */
  .custom-popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 300px;
  }
