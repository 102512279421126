.thresholds-container {
    padding: 16px 24px;
    width: 542px;

    > div {
        padding: 1rem;
        padding-right: 1.5rem;
        border-radius: 1rem;
    }

    .threshold {
        display: flex;
        margin-left: 18px;

        .left-line {
            width: 40px;
            border-left: 1px solid rgba(0,0,0,0.06);
            flex: 0;

            .colored-ball {
                width: 10px;
                height: 10px;
                border-radius: 100px;
                margin-left: -5.5px;
                margin-top: 25px;
            }
        }

        .threshold-content {
            padding: 10px;
            flex: 1;
            border: 1px solid var(--colorBorderSecondary);
            border-radius: 0.5rem;
            margin-left: 1rem;
            margin-bottom: 1rem;

            .threshold-header {
                padding: 0.5rem 0.5rem 0.5rem 1rem;
                border-radius: 0.25rem;

                > div {
                    display: flex;
                    align-items: center;

                    > h5 {
                        font-size: 16px;
                        margin: 0;
                    }

                    > div {
                        flex: 0;
                    }
                }
            }

            .dollar-amount {
                padding: 0.5rem 0.5rem 0.5rem 1rem;
            }
            .not-set {
                padding: 0.5rem 0.75rem 0.5rem 0.75rem;
                display: inline-block;
            }
            .avatar-container {
                border-radius: 1rem;
                padding: 0.5rem;
                display: inline-block;
            }
        }

        .threshold-not-equal {
            background-image: url(./../../assets/images/icons/not-equal.svg);
            height: 10px;
            width: 10px;
            zoom: 1.5;
            display: block;
        }
    }
}

.threshold-edit {
    .threshold-section-title {
        border-radius: 0.25rem;
        border-color: var(--colorFillSecondary) !important;

        > div {
            display: flex;
            align-items: center;
            padding: 0.25rem 0.5rem;

            h5 {
                font-size: 14px;
                margin: 0;
                flex: 1;
            }
        }
    }
    .threshold-tabs {
        margin-top: 8px;
        height: auto;

        .user-icon {
            position: absolute;
            z-index: 1;
            margin: 8px;
        }

        .ant-select-selection-search, .ant-select-selection-item {
            padding-left: 16px;
        }
    }
    .threshold-approvers {
        .threshold-approver {
            display: flex;
            align-items: center;

            > div:first-child {
                width: 20px;
                flex: 0;
                min-width: 20px;
            }

            .data-block {
                display: flex;
                border-radius: 4px;
                padding: 4px 8px;
                margin-bottom: 4px;
                align-items: center;
            }
        }
    }
}
